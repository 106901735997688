'use client';

import Link from "next/link";
import React from "react";
import { FaRedditAlien } from "react-icons/fa";
import { FaBluesky } from "react-icons/fa6";
import AddBookDialog from "../book/AddBookDialog";
import { usePathname } from "next/navigation";
const Footer = ({
  currentYear = 2025
}: {
  currentYear?: number;
}) => {
  const pathname = usePathname();
  const showFooterInMobile = !pathname.startsWith("/lists/new") && !/^\/lists\/[^/]+\/edit$/.test(pathname);
  if (!showFooterInMobile) return null;
  return <footer className="w-full max-lg:px-10 max-lg:gap-6 max-lg:flex-col-reverse lg:px-20 flex items-center justify-between font-medium text-sm bg-[#181A1B] max-lg:pt-6 max-lg:pb-[40px] text-white/40 h-full lg:h-28" data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
      <span className="lg:hidden flex items-center gap-1">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="Footer.tsx">
          <g clipPath="url(#clip0_2739_19441)" data-sentry-element="g" data-sentry-source-file="Footer.tsx">
            <path d="M0.875 7C0.875 8.62445 1.52031 10.1824 2.66897 11.331C3.81763 12.4797 5.37555 13.125 7 13.125C8.62445 13.125 10.1824 12.4797 11.331 11.331C12.4797 10.1824 13.125 8.62445 13.125 7C13.125 5.37555 12.4797 3.81763 11.331 2.66897C10.1824 1.52031 8.62445 0.875 7 0.875C5.37555 0.875 3.81763 1.52031 2.66897 2.66897C1.52031 3.81763 0.875 5.37555 0.875 7ZM14 7C14 8.85652 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85652 14 7 14C5.14348 14 3.36301 13.2625 2.05025 11.9497C0.737498 10.637 0 8.85652 0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7ZM7.12775 4.368C6.06725 4.368 5.44162 5.173 5.44162 6.55725V7.48475C5.44162 8.85938 6.05675 9.639 7.12775 9.639C7.98438 9.639 8.56363 9.12625 8.64062 8.39825H9.77375V8.47962C9.68625 9.74662 8.589 10.6383 7.1225 10.6383C5.29288 10.6383 4.26213 9.46925 4.26213 7.48563V6.54675C4.26213 4.56838 5.313 3.3635 7.12337 3.3635C8.59425 3.3635 9.6915 4.28575 9.77375 5.614V5.691H8.64062C8.56363 4.92188 7.96863 4.368 7.12775 4.368Z" fill="white" fillOpacity="0.4" data-sentry-element="path" data-sentry-source-file="Footer.tsx" />
          </g>
          <defs data-sentry-element="defs" data-sentry-source-file="Footer.tsx">
            <clipPath id="clip0_2739_19441" data-sentry-element="clipPath" data-sentry-source-file="Footer.tsx">
              <rect width="14" height="14" fill="white" data-sentry-element="rect" data-sentry-source-file="Footer.tsx" />
            </clipPath>
          </defs>
        </svg>
        {currentYear} Kaguya Media Pvt Ltd
      </span>

      <div className="flex max-lg:flex-wrap max-lg:mt-2 max-lg:gap-y-6 max-lg:justify-center gap-3 items-center">
        <span className="max-lg:hidden flex items-center gap-1">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="Footer.tsx">
            <g clipPath="url(#clip0_2739_19441)" data-sentry-element="g" data-sentry-source-file="Footer.tsx">
              <path d="M0.875 7C0.875 8.62445 1.52031 10.1824 2.66897 11.331C3.81763 12.4797 5.37555 13.125 7 13.125C8.62445 13.125 10.1824 12.4797 11.331 11.331C12.4797 10.1824 13.125 8.62445 13.125 7C13.125 5.37555 12.4797 3.81763 11.331 2.66897C10.1824 1.52031 8.62445 0.875 7 0.875C5.37555 0.875 3.81763 1.52031 2.66897 2.66897C1.52031 3.81763 0.875 5.37555 0.875 7ZM14 7C14 8.85652 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85652 14 7 14C5.14348 14 3.36301 13.2625 2.05025 11.9497C0.737498 10.637 0 8.85652 0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7ZM7.12775 4.368C6.06725 4.368 5.44162 5.173 5.44162 6.55725V7.48475C5.44162 8.85938 6.05675 9.639 7.12775 9.639C7.98438 9.639 8.56363 9.12625 8.64062 8.39825H9.77375V8.47962C9.68625 9.74662 8.589 10.6383 7.1225 10.6383C5.29288 10.6383 4.26213 9.46925 4.26213 7.48563V6.54675C4.26213 4.56838 5.313 3.3635 7.12337 3.3635C8.59425 3.3635 9.6915 4.28575 9.77375 5.614V5.691H8.64062C8.56363 4.92188 7.96863 4.368 7.12775 4.368Z" fill="white" fillOpacity="0.4" data-sentry-element="path" data-sentry-source-file="Footer.tsx" />
            </g>
            <defs data-sentry-element="defs" data-sentry-source-file="Footer.tsx">
              <clipPath id="clip0_2739_19441" data-sentry-element="clipPath" data-sentry-source-file="Footer.tsx">
                <rect width="14" height="14" fill="white" data-sentry-element="rect" data-sentry-source-file="Footer.tsx" />
              </clipPath>
            </defs>
          </svg>
          {currentYear} Kaguya Media Pvt Ltd
        </span>
        <span className="max-lg:hidden text-[9px] ">•</span>
        <Link href="/privacy-policy" rel="nofollow" className="hover:underline" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
          Privacy
        </Link>
        <span className="text-[9px] ">•</span>
        <Link href="/terms" rel="nofollow" className="hover:underline" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
          Terms
        </Link>
        <span className="text-[9px]">•</span>
        <Link href="/community-guidelines" rel="nofollow" className="hover:underline" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
          Guidelines
        </Link>
        <span className="max-lg:hidden text-[9px] ">•</span>
        <Link className="hover:underline" rel="nofollow" href={"/support"} data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
          Help & Support
        </Link>
        <span className="text-[9px] ">•</span>
        <Link href="/team" rel="nofollow" className="hover:underline" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
          Team
        </Link>
        <span className="text-[9px] ">•</span>

        <AddBookDialog data-sentry-element="AddBookDialog" data-sentry-source-file="Footer.tsx" />
      </div>

      <div className="flex gap-4 items-center">
        <Link href={"https://reddit.com/r/kaguya"} target="_blank" className="rounded-[50%] flex items-center justify-center cursor-pointer bg-white/[4%] size-11" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
          <FaRedditAlien size={21} className="text-white" data-sentry-element="FaRedditAlien" data-sentry-source-file="Footer.tsx" />
        </Link>
        <Link href={"https://x.com/KaguyaHQ"} target="_blank" className="rounded-[50%] flex items-center justify-center cursor-pointer bg-white/[4%] size-11" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="Footer.tsx">
            <path d="M15.2716 2.54761H18.0828L11.9411 9.56718L19.1663 19.1192H13.509L9.07804 13.326L4.00796 19.1192H1.19503L7.7642 11.611L0.833008 2.54761H6.63393L10.6392 7.84288L15.2716 2.54761ZM14.2849 17.4366H15.8427L5.7875 4.14188H4.11589L14.2849 17.4366Z" fill="white" data-sentry-element="path" data-sentry-source-file="Footer.tsx" />
          </svg>
        </Link>
        <Link href={"https://bsky.app/profile/kaguya.io"} target="_blank" className="rounded-[50%] flex items-center justify-center cursor-pointer bg-white/[4%] size-11" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
          <FaBluesky size={21} className="text-white" data-sentry-element="FaBluesky" data-sentry-source-file="Footer.tsx" />
        </Link>
        <Link href="https://discord.gg/wRjmwVxWEh" target="_blank" className="rounded-[50%] flex items-center justify-center cursor-pointer bg-white/[4%] size-11" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
          <svg width="25" height="19" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="Footer.tsx">
            <path d="M20.6222 2.46843C19.117 1.76419 17.5076 1.25237 15.825 0.960938C15.6183 1.33454 15.3769 1.83705 15.2104 2.2368C13.4218 1.9678 11.6496 1.9678 9.89379 2.2368C9.72737 1.83705 9.48047 1.33454 9.27197 0.960938C7.58754 1.25237 5.97626 1.76607 4.47106 2.47216C1.43505 7.05996 0.612043 11.5338 1.02355 15.9441C3.03719 17.4479 4.98864 18.3613 6.90717 18.9591C7.38086 18.3071 7.80333 17.6141 8.16728 16.8837C7.47413 16.6204 6.81024 16.2953 6.18294 15.918C6.34935 15.7947 6.51214 15.6658 6.66941 15.5332C10.4955 17.3227 14.6526 17.3227 18.433 15.5332C18.5921 15.6658 18.7549 15.7947 18.9195 15.918C18.2903 16.2972 17.6246 16.6222 16.9315 16.8856C17.2954 17.6141 17.7161 18.309 18.1916 18.9609C20.112 18.3632 22.0652 17.4497 24.0789 15.9441C24.5617 10.8314 23.254 6.39868 20.6222 2.46843ZM8.68853 13.2318C7.53998 13.2318 6.59808 12.1596 6.59808 10.8539C6.59808 9.54812 7.51987 8.47403 8.68853 8.47403C9.85723 8.47403 10.7991 9.54624 10.779 10.8539C10.7808 12.1596 9.85723 13.2318 8.68853 13.2318ZM16.4139 13.2318C15.2653 13.2318 14.3234 12.1596 14.3234 10.8539C14.3234 9.54812 15.2452 8.47403 16.4139 8.47403C17.5825 8.47403 18.5245 9.54624 18.5043 10.8539C18.5043 12.1596 17.5825 13.2318 16.4139 13.2318Z" fill="white" data-sentry-element="path" data-sentry-source-file="Footer.tsx" />
          </svg>
        </Link>
      </div>
    </footer>;
};
export default Footer;