import { create } from "zustand"

interface UIState {
  showNavbar: boolean
  setShowNavbar: (showNavbar: boolean) => void
  isSkeletonLoading: boolean
  setIsSkeletonLoading: (isSkeletonLoading: boolean) => void
  isViewLoading: boolean
  setIsViewLoading: (isViewLoading: boolean) => void
}

const useUIStore = create<UIState>((set) => ({
  showNavbar: true,
  setShowNavbar: (showNavbar) => set({ showNavbar }),
  isSkeletonLoading: false,
  setIsSkeletonLoading: (isSkeletonLoading) => set({ isSkeletonLoading }),
  isViewLoading: false,
  setIsViewLoading: (isViewLoading) => set({ isViewLoading }),
}))

export default useUIStore
