import React from "react";
import { HiBell } from "react-icons/hi2";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import NotificationCard from "../notifications/NotificationCard";
import { Button } from "../ui/button";
import { MarkAllNotificationsReadDocument, MyNotificationsDocument, Notification, User } from "@/graphql/generated/types";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { useUser } from "@/contexts/user";
import { usePathname, useRouter } from "next/navigation";
import { LuBell } from "react-icons/lu";
import { cn } from "@/lib/utils";
const LIMIT = 12;
const Notifications = ({
  myUsername,
  variant = "popover"
}: {
  variant?: "popover" | "link";
  myUsername?: User["username"];
}) => {
  const {
    user: authUser
  } = useUser();
  const apolloClient = useApolloClient();
  const [markAllNotificationsRead] = useMutation(MarkAllNotificationsReadDocument);
  const {
    data: notificationsData,
    refetch
  } = useQuery(MyNotificationsDocument, {
    variables: {
      limit: LIMIT
    },
    skip: !authUser
  });
  const router = useRouter();
  const [isOpen, setIsOpen] = React.useState(false);
  const pathname = usePathname();
  React.useEffect(() => {
    if (authUser && refetch) {
      refetch({
        limit: LIMIT
      });
    }
  }, [pathname, refetch, authUser]);
  React.useEffect(() => {
    setIsOpen(false);
  }, [pathname]);
  const handleMarkAllAsRead = async () => {
    markAllNotificationsRead({
      optimisticResponse: {
        __typename: "RootMutationType",
        markAllNotificationsRead: true
      },
      update: (cache, {
        data
      }) => {
        if (!data?.markAllNotificationsRead) return;
        const existingNotifications = cache.readQuery({
          query: MyNotificationsDocument,
          variables: {
            limit: LIMIT
          }
        });
        if (existingNotifications?.myNotifications?.items) {
          const updatedNotifications = existingNotifications.myNotifications.items.map(notification => notification ? {
            ...notification,
            read: true
          } : null).filter((notification): notification is NonNullable<typeof notification> => notification !== null);
          cache.writeQuery({
            query: MyNotificationsDocument,
            variables: {
              limit: LIMIT
            },
            data: {
              ...existingNotifications,
              myNotifications: {
                ...existingNotifications.myNotifications,
                items: updatedNotifications
              }
            }
          });
        }

        // Update unread count
        cache.writeQuery({
          query: MyNotificationsDocument,
          variables: {
            onlyUnread: true
          },
          data: {
            unread: {
              items: []
            }
          }
        });
      }
    });
    setIsOpen(false);
  };
  const notifications = notificationsData?.myNotifications?.items || [];
  const unreadCount = notificationsData?.unread?.items?.length || 0;
  if (variant === "link") {
    return <div className="relative size-fit flex cursor-pointer items-center justify-center">
        <LuBell className={cn("text-white size-6", pathname === "/notifications" ? "opacity-100" : "opacity-65")} />{" "}
        <span className="sr-only">Notifications</span>
        {unreadCount > 0 && <div className="absolute -right-0.5 -top-1 inline-flex h-3.5 w-3.5 items-center justify-center rounded-full bg-[#5069ce] text-[10px] leading-4 font-semibold text-white">
            {unreadCount}
          </div>}
      </div>;
  }
  const handleViewAll = () => {
    apolloClient.cache.evict({
      fieldName: "myNotifications"
    });
    apolloClient.cache.gc();
    router.push(`/notifications`);
    setIsOpen(false);
  };
  return <Popover open={isOpen} onOpenChange={setIsOpen} data-sentry-element="Popover" data-sentry-component="Notifications" data-sentry-source-file="Notifications.tsx">
      <PopoverTrigger data-sentry-element="PopoverTrigger" data-sentry-source-file="Notifications.tsx">
        <div className="relative flex h-11 w-11 cursor-pointer items-center justify-center rounded-full bg-white/5">
          <HiBell size={20} color="white" data-sentry-element="HiBell" data-sentry-source-file="Notifications.tsx" />
          <span className="sr-only">Notifications</span>
          {unreadCount > 0 && <div className="absolute -right-0 -top-0 inline-flex h-4 w-4 items-center justify-center rounded-full bg-[#5069ce] text-xs font-normal text-white">
              {unreadCount}
            </div>}
        </div>
      </PopoverTrigger>
      <PopoverContent style={{
      filter: "drop-shadow(1px 10px 10px rgba(10, 25px, 30, 0.30))"
    }} side="bottom" sideOffset={8} align="end" alignOffset={-86} className="h-[556px] z-[101] w-[396px] rounded-[8px] border-[#34343a] bg-[#121320] p-0 pb-2 pt-0 text-white" data-sentry-element="PopoverContent" data-sentry-source-file="Notifications.tsx">
        <div className="custom-dropdown-scrollbar h-full overflow-y-auto overflow-x-hidden">
          <div className="px-6 border-b border-b-[#34343a] flex items-center justify-between py-4 text-base font-semibold text-white">
            <div className="flex items-center gap-x-2">
              <span>Notifications</span>
              {unreadCount > 0 && <div className="size-6 bg-[#5069ce] text-sm font-medium flex items-center justify-center rounded-full">
                  {unreadCount}
                </div>}
            </div>

            <Button onClick={handleMarkAllAsRead} variant="link" className="text-xs h-fit p-0 font-medium text-[#7187e1]" data-sentry-element="Button" data-sentry-source-file="Notifications.tsx">
              Mark all as read
            </Button>
          </div>

          {/* <div className="flex items-center h-5/6 justify-center">
            <p className="text-sm text-center text-[#e6e6fa]/80">
              No notifications available
            </p>
           </div> */}
          {notifications?.length > 0 ? <div className="divide-y-[1px] divide-[#34343a]">
              {notifications.map(notification => <NotificationCard myUsername={myUsername} key={notification?.id} notification={notification as Notification} variant="navbar" />)}
            </div> : <div className="h-[480px] flex items-center justify-center">
              <span className="text-[#e6e6fa]/90 text-sm">
                No new notifications
              </span>
            </div>}
        </div>

        <Button onClick={handleViewAll} variant="link" className="flex w-full items-center justify-center h-12 bg-[#121320] rounded-t-[0px] rounded-b-[8px] border border-[#34343a] 
               text-center font-medium text-[#7187e1] 
               hover:underline leading-[3rem]" data-sentry-element="Button" data-sentry-source-file="Notifications.tsx">
          View All
        </Button>
      </PopoverContent>
    </Popover>;
};
export default Notifications;